import React, { Component } from "react";
import { connect } from "react-redux";
import queryString from "query-string";

import Heading from "./heading";
import PersonalInformation from "./personalInformation";
import Address from "./address";
import IdenticalInformation from "./identificationInformation";
import FamilyInformation from "./familyInformation";
import Education from "./education";
import { isEmpty, dataURLtoFile } from "../../helpers";
import Trainings from "./trainings";
import WorkAndExperience from "./workAndExperience";
import References from "./references";
import CoverLetterAndCV from "./coverLetterAndCV";
import PaymentVoucher from "./paymentVoucher";
import FinalSubmit from "./finalSubmit";
import { formatedShortMonthDayYear } from "../../helpers/dateFormat";

import {
  GetCountryList,
  GetDistrictList,
  GetEducationLevel,
  GetProvienceList,
  GetDistrictByProvince,
  GetLocalBodyByDistrict,
  GetNationality,
  UploadFiles,
  DeleteFiles,
} from "../../store/actions/common";
import { SaveJobApply, GetJobDetail } from "../../store/actions/jobAction";
import { DisplayClientSideMessage } from "../../store/actions/common";

import {
  FinalDataToSave_Template,
  RecruitmentCandidateDto,
  RecruitmentCandidateEducationInfoDto,
  RecruitmentCandidateReferenceInfoDto,
  RecruitmentCandidateTrainingInfoDto,
  RecruitmentCandidateWorkExperienceInfoDto,
} from "./dataTemplates";

import { Mandatory, Off, Optional, jobApply_components } from "./enum";
import { validateJobApply } from "./validation";
// import { folderNameToSaveFile_apply } from "../constants";
import {
  validFileExtension,
  validApplicationExtension,
  validUploadDocExtension,
} from "../../constants/validExtensions";
import { getSaveData } from "./getSaveData";
import { fileKeys } from "./constants";
import ExpectedSalary from "./expecetdSalary";

function educationTemplate() {
  return {
    degreeName: null,
    qualificationLevel: null,
    institution: null,
    country: null,
    university: null,
    year: null,
    gradeTypeId: 1,
    grade: null,
    majorSubject: null,
  };
}

function trainingTemplate() {
  return {
    trainingName: null,
    institution: null,
    country: null,
    year: null,
  };
}

function workAndExperienceTemplate() {
  return {
    organization: null,
    country: null,
    place: null,
    fromYear: null,
    toYear: null,
    position: null,
    responsibilities: null,
  };
}

function referenceTemplate() {
  return {
    name: null,
    organization: null,
    position: null,
    phone: null,
    email: null,
  };
}

class JobApplyScreen extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      // isEligibleToApply: true,
      heading: {
        jobId: 0,
        jobTitle: null,
        department: null,
        branch: null,
        publishedOn: null,
        endsOn: null,
        // remainingDays: 0,
        // salaryRangeFrom: 0,
        // salaryrangeTo: 0,
        // skills: null,
        // employeeType: null,
        // education: null,
        // experience: null,
        // jobDescription: null,
        // showExperienceSection: true,
        // showPay: true,

        personalInformationRequiredType: Optional,
        addressRequiredType: Optional,
        familyInformationRequiredType: Optional,
        expectedSalaryRequiredType: Optional,
        identificationInformationRequiredType: Optional,
        educationRequiredType: Optional,
        trainingRequiredType: Optional,
        workExperienceRequiredType: Optional,
        referenceRequiredType: Optional,
        coverLetterRequiredType: Optional,
        resumeRequiredType: Optional,
        voucherRequiredType: Optional,
        enableEmployeeId: false,
      },
      personalInformation: {
        honorificType: null,
        firstName: null,
        lastName: null,
        mobileNumber: null,
        email: null,
        nationality: null,
        employeeId: null,
        dob: null,
        photo: null,
        cropedPhoto: null,
        isPhotoSelectionCompleted: false,
      },
      address: {
        permanentProvience: null,
        permanentDistrict: null,
        permanentLocalBody: null,
        permanentWard: null,
        permanentLocality: null,

        correspondingProvience: null,
        correspondingDistrict: null,
        correspondingLocalBody: null,
        correspondingWard: null,
        correspondingLocality: null,
      },
      identificationInformation: {
        documentTypeId: null, // 1=citizenship, 2=passport
        havePassport: null, //1=have ,  2=dont have
        drivingLicenseTypeId: null, //1=none, 2=two wheeler, 3=four wheeler, 4=both two and four weeler
      },
      familyInformation: {
        fatherName: null,
        motherName: null,
        grandFatherName: null,
        maritalStatusId: null, //1=single 2=married,
        spouseName: null,
        fatherInLawName: null,
      },
      expectedSalaryInfo: {
        expectedSalary: null,
      },
      educations: [],
      trainings: [],
      workAndExperiences: [],
      references: [],
      fileCollection: {
        // identificationFiles: [],
        coverLetterFile: null,
        cvFile: null,
        voucherFile: null,
      },
      errors: {},
    };
    this.onError = this.onError.bind(this);
    this.onChange = this.onChange.bind(this);
    this.onChangeAndGet = this.onChangeAndGet.bind(this);
    this.onChangeList = this.onChangeList.bind(this);
    this.onFileSelectionList = this.onFileSelectionList.bind(this);
    this.onMultipleFileSelectionForList =
      this.onMultipleFileSelectionForList.bind(this);
    this.onMultipleFileSelection = this.onMultipleFileSelection.bind(this);
    this.onNewList = this.onNewList.bind(this);
    this.onConfirmList = this.onConfirmList.bind(this);
    this.onRemoveList = this.onRemoveList.bind(this);
    this.onEditList = this.onEditList.bind(this);
    this.onDeleteList = this.onDeleteList.bind(this);
    this.onSubmit = this.onSubmit.bind(this);

    this.onSameAsPermanent = this.onSameAsPermanent.bind(this);
  }

  async componentDidMount() {
    let params = queryString.parse(this.props.location.search);

    if (params && params.jobId) {
      this.initialize();
      await this.props.GetJobDetail(params.jobId);
    }

    const { RecruitmentJobApplicationForm, RecruitmentPositionDetail } =
      this.props.jobDetail;

    const {
      Id,
      ExternalJobTitle,
      Department,
      Branch,
      PublishedDateEng,
      EndDateEng,
      EnableEmployeeId,
    } = RecruitmentPositionDetail;

    let { heading } = this.state;
    heading.jobId = Id;
    heading.jobTitle = ExternalJobTitle;
    heading.department = Department;
    heading.branch = Branch;
    heading.publishedOn =
      PublishedDateEng && formatedShortMonthDayYear(PublishedDateEng);
    heading.endsOn = EndDateEng && formatedShortMonthDayYear(EndDateEng);
    heading.enableEmployeeId = EnableEmployeeId;
    try {
      if (
        RecruitmentJobApplicationForm &&
        RecruitmentJobApplicationForm.length > 0
      ) {
        heading.personalInformationRequiredType =
          RecruitmentJobApplicationForm.find(
            (f) => f.ComponentRef_Id == jobApply_components.Personal_Information
          )?.RequiredType || false;
        heading.addressRequiredType = RecruitmentJobApplicationForm.find(
          (f) => f.ComponentRef_Id == jobApply_components.Address
        )?.RequiredType || false;
        heading.familyInformationRequiredType =
          RecruitmentJobApplicationForm.find(
            (f) => f.ComponentRef_Id == jobApply_components.Family_Information
          )?.RequiredType || false;
        heading.expectedSalaryRequiredType = RecruitmentJobApplicationForm.find(
          (f) => f.ComponentRef_Id == jobApply_components.Expected_Salary
        )?.RequiredType || false;
        heading.identificationInformationRequiredType =
          RecruitmentJobApplicationForm.find(
            (f) =>
              f.ComponentRef_Id ==
              jobApply_components.Identification_Information
          )?.RequiredType || false;
        heading.educationRequiredType = RecruitmentJobApplicationForm.find(
          (f) => f.ComponentRef_Id == jobApply_components.Education
        )?.RequiredType || false;

        heading.workExperienceRequiredType = RecruitmentJobApplicationForm.find(
          (f) => f.ComponentRef_Id == jobApply_components.Work_Experience
        )?.RequiredType || false;
        heading.referenceRequiredType = RecruitmentJobApplicationForm.find(
          (f) => f.ComponentRef_Id == jobApply_components.References
        )?.RequiredType || false;
        heading.coverLetterRequiredType = RecruitmentJobApplicationForm.find(
          (f) => f.ComponentRef_Id == jobApply_components.Cover_Letter
        )?.RequiredType || false;
        heading.resumeRequiredType = RecruitmentJobApplicationForm.find(
          (f) => f.ComponentRef_Id == jobApply_components.Resume
        )?.RequiredType || false;
        heading.voucherRequiredType = RecruitmentJobApplicationForm.find(
          (f) => f.ComponentRef_Id == jobApply_components.Payment_Voucher
        )?.RequiredType || false;
        heading.trainingRequiredType = RecruitmentJobApplicationForm.find(
          (f) => f.ComponentRef_Id == jobApply_components.Training
        )?.RequiredType || false;
      }
    } catch (ex) {}

    this.setState({ heading: heading });
  }

  initialize = () => {
    this.props.GetCountryList();
    this.props.GetDistrictList();
    this.props.GetEducationLevel();
    this.props.GetProvienceList();
    this.props.GetNationality();
  };

  onError(message) {
    this.props.DisplayClientSideMessage(message);
  }

  onChange(title, name, value) {
    // if (title == "fileCollection" && value) {
    //   const validExtensions = [...validUploadDocExtension];

    //   let fileExtension = null;
    //   if (value && value.name) {
    //     fileExtension = value.name.split(".")[1];
    //   }

    //   let isValidExtension = validExtensions.find(
    //     (f) => f == (fileExtension && fileExtension.toLowerCase())
    //   )
    //     ? true
    //     : false;

    //   if (!isValidExtension) {
    //     this.props.DisplayClientSideMessage(
    //       "Valid extension are " + validExtensions
    //     );
    //     return false;
    //   }
    // }

    this.setState(
      (prevState) => ({
        ...prevState,
        [title]: {
          ...prevState[title],
          [name]: value,
        },
      }),
      () => {
        if (!isEmpty(this.state.errors)) {
          this.validateForm();
        }
      }
    );
  }

  async onChangeAndGet(title, name, param, targetName, targetList) {
    const { id } = param;
    if (targetName == "district") {
      await this.props.GetDistrictByProvince(id);
      this.onChange(title, targetList, this.props.districtByProvienceList);
    } else if (targetName == "localBody") {
      await this.props.GetLocalBodyByDistrict(id);
      this.onChange(title, targetList, this.props.localBodyList);
    }
  }

  onChangeList(listName, key, title, name, value) {
    let list = this.state[listName];
    let currentData = list.find((f) => f.key == key);
    currentData[title][name] = value;
    this.updateState(listName, list);
  }

  async onFileSelectionList(listName, key, title, file) {
    let list = this.state[listName];
    let currentData = list.find((f) => f.key == key);

    await this.props.UploadFiles(file);

    currentData[title]["file"] = this.props.uploadedFileList[0] || null;

    this.updateState(listName, list);
  }

  async onMultipleFileSelectionForList(listName, key, title, files) {
    let list = this.state[listName];
    let currentData = list.find((f) => f.key == key);

    await this.props.UploadFiles(null, files);

    currentData[title]["files"] = this.props.uploadedFileList || [];

    this.updateState(listName, list);
  }

  async onMultipleFileSelection(stateName, name, files) {
    let list = this.state[stateName];

    await this.props.UploadFiles(null, files);

    list[name] = this.props.uploadedFileList || [];

    this.setState({ [stateName]: list });
  }

  updateState = (listName, list) => {
    this.setState((prevState) => ({
      ...prevState,
      //...(prevState[listName] = list)
      [listName]: list,
    }));
  };

  onNewList(listName, title, key) {
    let list = this.state[listName];

    //make isSelected to false
    let selectedData = list && list.find((f) => f.isSelected);
    if (!isEmpty(selectedData)) {
      selectedData.isSelected = false;
    }

    //remove if exist with same key
    list = list.filter((f) => f.key != key);

    let dynamicFunction = {};
    if (listName == "educations") {
      dynamicFunction = educationTemplate();
    } else if (listName == "trainings") {
      dynamicFunction = trainingTemplate();
    } else if (listName == "workAndExperiences") {
      dynamicFunction = workAndExperienceTemplate();
    } else if (listName == "references") {
      dynamicFunction = referenceTemplate();
    }

    list.push({
      key: key,
      [title]: dynamicFunction,
      isSelected: true,
    });

    this.updateState(listName, list);
  }

  onConfirmList(listName, key) {
    let list = this.state[listName];

    let selectedData = list && list.find((f) => f.key == key);
    if (!isEmpty(selectedData)) {
      selectedData.isSelected = false;
    }

    this.updateState(listName, list);
  }

  onRemoveList(listName, key) {
    let list = this.state[listName];
    //remove
    list = list.filter((f) => f.key != key);
    this.updateState(listName, list);
  }

  onEditList(listName, key) {
    let list = this.state[listName];

    let selectedData = list && list.find((f) => f.isSelected);
    if (!isEmpty(selectedData)) {
      selectedData.isSelected = false;
    }

    let currentData = list && list.find((f) => f.key == key);
    if (!isEmpty(currentData)) {
      currentData.isSelected = true;
    }

    this.updateState(listName, list);
  }

  async onDeleteList(listName, key) {
    let list = this.state[listName];

    //#region  delete files from server
    if (listName === "educations" || listName === "trainings") {
      let currentData = list && list.find((f) => f.key == key);
      let params = null;
      if (listName == "educations") {
        let currentFiles = currentData["education"].files;
        let fileNames = [];
        if (currentFiles && currentFiles.length > 0) {
          currentFiles.map((cf) => {
            fileNames.push(cf.ServerFileName);
          });
        }
        params = { FileNames: fileNames };
      } else if (listName == "trainings") {
        let currentFile = currentData["training"].file;
        params = { FileNames: [currentFile.ServerFileName] };
      }

      params && (await this.props.DeleteFiles(params));
    }
    //#endregion

    list = list.filter((f) => f.key != key);

    this.updateState(listName, list);
  }

  onSameAsPermanent() {
    const {
      permanentProvience,
      permanentDistrict,
      permanentLocalBody,
      permanentWard,
      permanentLocality,
      permanentDistrictList,
      permanentLocalBodyList,
    } = this.state.address;

    this.setState(
      (prevState) => ({
        ...prevState,
        address: {
          ...prevState.address,
          correspondingProvience: permanentProvience,
          correspondingDistrict: permanentDistrict,
          correspondingLocalBody: permanentLocalBody,
          correspondingWard: permanentWard,
          correspondingLocality: permanentLocality,
          correspondingDistrictList: permanentDistrictList,
          correspondingLocalBodyList: permanentLocalBodyList,
        },
      }),
      () => {
        if (!isEmpty(this.state.errors)) {
          this.validateForm();
        }
      }
    );
  }

  validateForm = async () => {
    const {
      personalInformation,
      address,
      identificationInformation,
      familyInformation,
      expectedSalaryInfo,
      fileCollection,
      heading,
      educations,
      trainings,
      workAndExperiences,
      references,
      expectedSalary
    } = this.state;


    const blockRequiredType = {
      identificationInformation: heading.identificationInformationRequiredType,
      familyInformation: heading.familyInformationRequiredType,
      expectedSalaryInfo: heading.expectedSalaryRequiredType,
      educations: heading.educationRequiredType,
      trainings: heading.trainingRequiredType,
      workAndExperiences: heading.workExperienceRequiredType,
      references: heading.referenceRequiredType,
      coverLetterFile: heading.coverLetterRequiredType,
      cvFile: heading.resumeRequiredType,
      voucherFile: heading.voucherRequiredType,
      enableEmployee: heading.enableEmployeeId,
    };

    //if any block is selected then it will be unable to save
    const blockData = {
      educations:
        educations && educations.find((f) => f.isSelected) ? [] : educations,
      trainings:
        trainings && trainings.find((f) => f.isSelected) ? [] : trainings,
      workAndExperiences:
        workAndExperiences && workAndExperiences.find((f) => f.isSelected)
          ? []
          : workAndExperiences,
      references:
        references && references.find((f) => f.isSelected) ? [] : references,
    };

    let validateData = {
      personalInformation,
      address,
      identificationInformation,
      familyInformation,
      expectedSalaryInfo,
      fileCollection,

      blockRequiredType,
      blockData,
    };

    let errors = await validateJobApply(validateData);

    this.setState({ errors: errors, isSubmitted: true });
    return errors;
  };

  async onSubmit() {
    this.setState({ isLoading: true });

    const {
      heading,
      personalInformation,
      address,
      identificationInformation,
      familyInformation,
      expectedSalaryInfo,
      educations,
      trainings,
      workAndExperiences,
      references,
    } = this.state;

    const { jobId } = heading;

    let {
      honorificType,
      firstName,
      lastName,
      mobileNumber,
      email,
      nationality,
      dob,
      employeeId,
    } = personalInformation;

    const {
      permanentProvience,
      permanentDistrict,
      permanentLocalBody,
      permanentWard,
      permanentLocality,
      correspondingProvience,
      correspondingDistrict,
      correspondingLocalBody,
      correspondingWard,
      correspondingLocality,
    } = address;
    const {
      documentTypeId,
      havePassport,
      drivingLicenseTypeId,
      identificationFiles,
    } = identificationInformation;
    const {
      fatherName,
      motherName,
      grandFatherName,
      maritalStatusId,
      spouseName,
      fatherInLawName,
    } = familyInformation;
    const { expectedSalary } = expectedSalaryInfo;

    const nationalityId = nationality && nationality.value;

    const permanentProvienceId = permanentProvience && permanentProvience.value;
    const permanentDistrictId = permanentDistrict && permanentDistrict.value;
    const permanentLocalBodyId = permanentLocalBody && permanentLocalBody.value;
    const correspondingProvienceId =
      correspondingProvience && correspondingProvience.value;
    const correspondingDistrictId =
      correspondingDistrict && correspondingDistrict.value;
    const correspondingLocalBodyId =
      correspondingLocalBody && correspondingLocalBody.value;

    const errors = await this.validateForm();

    if (!isEmpty(errors)) {
      this.setState({ isLoading: false });
      this.props.DisplayClientSideMessage("Please fill all required fields.");
      return false;
    }

    let fileListWithKey = await this.prepareFilesForUpload();

    const recruitmentCandidateDto = RecruitmentCandidateDto({
      jobId,
      honorificType,
      firstName,
      lastName,
      mobileNumber,
      email,
      nationalityId,
      dob,
      // imageOriginalName,
      // imageName,
      // cropedImageOriginalName,
      // cropedImageName,
      permanentProvienceId,
      permanentDistrictId,
      permanentLocalBodyId,
      permanentWard,
      permanentLocality,
      correspondingProvienceId,
      correspondingDistrictId,
      correspondingLocalBodyId,
      correspondingWard,
      correspondingLocality,
      documentTypeId,
      // identificationDocumentName,
      // identificationDocumentOriginalName,
      havePassport,
      drivingLicenseTypeId,
      identificationFiles,
      fatherName,
      motherName,
      grandFatherName,
      maritalStatusId,
      spouseName,
      fatherInLawName,
      employeeId,
      // coverLetterDocumentOriginalName,
      // coverLetterDocumentName,
      // cvDocumentOriginalName,
      // cvDocumentName,
      expectedSalary,
    });

    //education
    let recruitmentCandidateEducationInfoDto = [];
    educations &&
      educations.map((data) => {
        const edu = data.education;

        const degreeName = edu.degreeName;
        const qualificationLevelId =
          edu.qualificationLevel && edu.qualificationLevel.value;
        const institutionName = edu.institution;
        const countryId = edu.country && edu.country.value;
        const universityName = edu.university;
        const passedYear = edu.year;
        const isPercentage =
          edu.gradeTypeId && edu.gradeTypeId == 1 ? true : false;
        const isGPA = edu.gradeTypeId && edu.gradeTypeId == 2 ? true : false;
        const gradeValue = edu.grade;
        const majorSubjects = edu.majorSubject;
        const uploadedFileList = edu.files;

        recruitmentCandidateEducationInfoDto.push(
          RecruitmentCandidateEducationInfoDto({
            degreeName,
            qualificationLevelId,
            institutionName,
            countryId,
            universityName,
            passedYear,
            isPercentage,
            isGPA,
            gradeValue,
            majorSubjects,
            uploadedFileList,
          })
        );
      });

    //training
    let recruitmentCandidateTrainingInfoDto = [];
    trainings &&
      trainings.map((data) => {
        const tr = data.training;

        const trainingName = tr.trainingName;
        const institutionName = tr.institution;
        const countryId = tr.country && tr.country.value;
        const year = tr.year;
        const trainingDocumentOriginalName = tr.file.UserFileName;
        const trainingDocumentName = tr.file.ServerFileName;
        recruitmentCandidateTrainingInfoDto.push(
          RecruitmentCandidateTrainingInfoDto({
            trainingName,
            institutionName,
            countryId,
            year,
            trainingDocumentOriginalName,
            trainingDocumentName,
          })
        );
      });

    //work and experience
    let recruitmentCandidateWorkExperienceInfoDto = [];
    workAndExperiences &&
      workAndExperiences.map((data) => {
        const war = data.workAndExperience;

        const organizationName = war.organization;
        const countryId = war.country && war.country.value;
        const placeName = war.place;
        const fromYear = war.fromYear; //formatedDate(war.fromYear);
        const toYear = war.toYear; // formatedDate(war.toYear);
        const position = war.position;
        const keyResponsibilities = war.responsibilities;
        recruitmentCandidateWorkExperienceInfoDto.push(
          RecruitmentCandidateWorkExperienceInfoDto({
            organizationName,
            countryId,
            placeName,
            fromYear,
            toYear,
            position,
            keyResponsibilities,
          })
        );
      });

    //references
    let recruitmentCandidateReferenceInfoDto = [];
    references &&
      references.map((data) => {
        const ref = data.reference;

        const referenceName = ref.name;
        const referenceOrganizationName = ref.organization;
        const referencePosition = ref.position;
        const referencePhoneNo = ref.phone;
        const referenceEmail = ref.email;
        recruitmentCandidateReferenceInfoDto.push(
          RecruitmentCandidateReferenceInfoDto({
            referenceName,
            referenceOrganizationName,
            referencePosition,
            referencePhoneNo,
            referenceEmail,
          })
        );
      });

    //final
    // let finalDataToSave_Template = FinalDataToSave_Template({
    //   recruitmentCandidateDto,
    //   recruitmentCandidateEducationInfoDto,
    //   recruitmentCandidateTrainingInfoDto,
    //   recruitmentCandidateWorkExperienceInfoDto,
    //   recruitmentCandidateReferenceInfoDto,
    // });

    let finalDataToSave_Template = getSaveData({
      recruitmentCandidateDto,
      recruitmentCandidateEducationInfoDto,
      recruitmentCandidateTrainingInfoDto,
      recruitmentCandidateWorkExperienceInfoDto,
      recruitmentCandidateReferenceInfoDto,
      fileListWithKey,
    });

    await this.props.SaveJobApply(finalDataToSave_Template, this.props);

    this.setState({ isLoading: false });
  }

  prepareFilesForUpload = async () => {
    const { personalInformation, fileCollection } = this.state;
    const {
      // identificationFiles,
      coverLetterFile,
      cvFile,
      voucherFile,
    } = fileCollection;

    let fileListWithKey = [];

    //convert base64 photo to file
    if (personalInformation.photo) {
      const photoName = personalInformation.photo.name;
      const photo = personalInformation.photo;

      fileListWithKey.push({
        file: photo,
        key: fileKeys.applicantPhoto,
      });

      if (personalInformation.cropedPhoto) {
        const cropedPhotoName = "croped_" + photoName;
        const base64_photo = personalInformation.cropedPhoto;
        const file_photo = await dataURLtoFile(base64_photo, cropedPhotoName);

        fileListWithKey.push({
          file: file_photo,
          key: fileKeys.applicantPhotoCroped,
        });
      }
    }

    if (coverLetterFile) {
      fileListWithKey.push({
        file: coverLetterFile,
        key: fileKeys.coverLetterFile,
      });
    }

    if (cvFile) {
      fileListWithKey.push({ file: cvFile, key: fileKeys.cvFile });
    }

    if (voucherFile) {
      fileListWithKey.push({ file: voucherFile, key: fileKeys.voucherFile });
    }

    return fileListWithKey;
  };

  render() {
    const {
      heading,
      personalInformation,
      address,
      identificationInformation,
      familyInformation,
      educations,
      trainings,
      workAndExperiences,
      references,
      paymentVoucher,
      fileCollection,
      expectedSalaryInfo,
      errors,
    } = this.state;

    const {
      personalInformationRequiredType,
      addressRequiredType,
      familyInformationRequiredType,
      identificationInformationRequiredType,
      educationRequiredType,
      trainingRequiredType,
      workExperienceRequiredType,
      referenceRequiredType,
      coverLetterRequiredType,
      resumeRequiredType,
      voucherRequiredType,
      expectedSalaryRequiredType,
    } = heading;

    // if (!this.state.isEligibleToApply) {
    //   return null;
    // }

    return (
      <div className="container jobApply">
        <Heading data={heading} />

        {/* {personalInformationRequiredType &&
          personalInformationRequiredType != Off && ( */}
        <PersonalInformation
          propsData={this.props}
          data={personalInformation}
          isMandatory={true}
          onChange={this.onChange}
          onError={this.onError}
          errors={errors}
        />
        {/* )} */}

        {/* {addressRequiredType && addressRequiredType != Off && ( */}
        <Address
          propsData={this.props}
          data={address}
          isMandatory={true}
          onChange={this.onChange}
          onChangeAndGet={this.onChangeAndGet}
          onSameAsPermanent={this.onSameAsPermanent}
          errors={errors}
        />
        {/* )} */}

        {identificationInformationRequiredType &&
        identificationInformationRequiredType != Off ? (
          <IdenticalInformation
            data={identificationInformation}
            fileCollection={fileCollection}
            isMandatory={identificationInformationRequiredType == Mandatory}
            onChange={this.onChange}
            onMultipleFileSelection={this.onMultipleFileSelection}
            errors={errors}
          />
        ) : null}

        {familyInformationRequiredType &&
        familyInformationRequiredType != Off ? (
          <FamilyInformation
            data={familyInformation}
            isMandatory={familyInformationRequiredType == Mandatory}
            onChange={this.onChange}
            errors={errors}
          />
        ) : null}

        {educationRequiredType && educationRequiredType != Off ? (
          <Education
            propsData={this.props}
            data={educations}
            isMandatory={educationRequiredType == Mandatory}
            onNewList={this.onNewList}
            onConfirmList={this.onConfirmList}
            onRemoveList={this.onRemoveList}
            onEditList={this.onEditList}
            onDeleteList={this.onDeleteList}
            onChange={this.onChange}
            onMultipleFileSelectionForList={this.onMultipleFileSelectionForList}
            onChangeList={this.onChangeList}
            blockError={errors && errors.educations}
          />
        ) : null}

        {trainingRequiredType && trainingRequiredType != Off ? (
          <Trainings
            propsData={this.props}
            data={trainings}
            isMandatory={trainingRequiredType == Mandatory}
            onNewList={this.onNewList}
            onConfirmList={this.onConfirmList}
            onRemoveList={this.onRemoveList}
            onEditList={this.onEditList}
            onDeleteList={this.onDeleteList}
            onChange={this.onChange}
            onChangeList={this.onChangeList}
            onFileSelectionList={this.onFileSelectionList}
            blockError={errors && errors.trainings}
          />
        ) : null}

        {workExperienceRequiredType && workExperienceRequiredType != Off ? (
          <WorkAndExperience
            propsData={this.props}
            data={workAndExperiences}
            isMandatory={workExperienceRequiredType == Mandatory}
            onNewList={this.onNewList}
            onConfirmList={this.onConfirmList}
            onRemoveList={this.onRemoveList}
            onEditList={this.onEditList}
            onDeleteList={this.onDeleteList}
            onChangeList={this.onChangeList}
            blockError={errors && errors.workAndExperiences}
          />
        ) : null}

        {referenceRequiredType && referenceRequiredType != Off ? (
          <References
            data={references}
            isMandatory={referenceRequiredType == Mandatory}
            onNewList={this.onNewList}
            onChangeList={this.onChangeList}
            onConfirmList={this.onConfirmList}
            onRemoveList={this.onRemoveList}
            onEditList={this.onEditList}
            onDeleteList={this.onDeleteList}
            blockError={errors && errors.references}
          />
        ) : null}

        {resumeRequiredType != Off || coverLetterRequiredType != Off ? (
          <CoverLetterAndCV
            fileCollection={fileCollection}
            resumeRequiredType={resumeRequiredType}
            coverLetterRequiredType={coverLetterRequiredType}
            onChange={this.onChange}
            errors={errors}
          />
        ) : null}
        {voucherRequiredType != Off ? (
          <PaymentVoucher
            fileCollection={fileCollection}
            voucherRequiredType={voucherRequiredType}
            onChange={this.onChange}
            errors={errors}
          />
        ) : null}
        {expectedSalaryRequiredType && expectedSalaryRequiredType != Off ? (
          <ExpectedSalary
            data={expectedSalaryInfo}
            isMandatory={expectedSalaryRequiredType == Mandatory}
            onChange={this.onChange}
            errors={errors}
          />
        ) : null}

        <FinalSubmit
          onSubmit={this.onSubmit}
          isLoading={this.state.isLoading}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  countryList: state.commonReducer.countryList,
  districtList: state.commonReducer.districtList,
  educationLevelList: state.commonReducer.educationLevelList,
  provinceList: state.commonReducer.provinceList,
  districtByProvienceList: state.commonReducer.districtByProvienceList,
  localBodyList: state.commonReducer.localBodyList,
  uploadedFileList: state.commonReducer.uploadedFileList,
  jobDetail: state.jobReducer.jobDetail,
  nationalityList: state.commonReducer.nationality,
});

const mapDispatchToProps = {
  GetCountryList,
  GetDistrictList,
  GetEducationLevel,
  GetProvienceList,
  GetDistrictByProvince,
  GetLocalBodyByDistrict,
  UploadFiles,
  DeleteFiles,
  SaveJobApply,
  GetJobDetail,
  GetNationality,
  DisplayClientSideMessage,
};
export default connect(mapStateToProps, mapDispatchToProps)(JobApplyScreen);
